<script setup lang="ts">
const { setLocale } = useI18n()
const route = useRoute()

onMounted(() => {
    setLocale((route.query.lang as string) || 'fr')
})
</script>

<template>
    <div
        class="min-h-login p-4 lg:p-9 flex flex-col h-full w-full items-center justify-center gap-9"
    >
        <Logo />
        <slot />
    </div>
</template>
